<template>
  <div class="add-container">
    <div></div>
    <div class="top-title">
      Add New Order
    </div>
    <a-steps :current="current" direction="vertical" class="steps" changeable @change="setCurrent">
      <a-step :status="status1">Shipper & Recipient
        <div>Address</div>
      </a-step>
      <a-step :status="status2">Shipment Information</a-step>
      <a-step status="wait" disabled>Pay Quotation</a-step>
    </a-steps>
    <div style="padding:88px  24px 0 262px;">
      <a-form ref="formRef" :model="form" layout="vertical">
        <a-collapse class="ShipperAddress" expand-icon-position="right" :default-active-key="['1']">
          <a-collapse-item header="Shipper" key="1">
            <template #expand-icon="{ active }">
              <a-button shape="circle" v-if="active">
                <icon-double-down />
              </a-button>
              <a-button shape="circle" v-else>
                <icon-double-up />
              </a-button>
            </template>
            <template #extra>
              <div class="select-address">
                <div class="selecr-title">Address</div>
                <a-config-provider :locale="enUS">
                  <a-select @change="senderAddressVal" v-model="senderSelect" placeholder="Please select" @click.stop=""
                    style="width:360px;" @popup-visible-change="selectFirst">
                    <a-option v-for="item, i in senderAddress.option" :value="item.name" :key="i">{{
                      item.name
                    }}
                    </a-option>
                  </a-select>
                </a-config-provider>
              </div>
            </template>
            <template #header>
              <div class="collapse-header">
                <div class="title">Shipper</div>
                <div class="address">
                  <span v-if="form.from_name">{{ form.from_name }},</span>
                  <span v-if="form.from_companyanme">{{ form.from_companyanme }},</span>
                  <span v-if="form.from_street1">{{ form.from_street1 }},</span>
                  <span v-if="form.from_street2">{{ form.from_street2 }},</span>
                  <span v-if="form.from_city">{{ form.from_city }},</span>
                  <span v-if="form.from_state">{{ form.from_state }}</span>
                </div>
              </div>
            </template>
            <a-row :gutter="[20]">
              <a-col :span="12">
                <a-form-item field="from_name" label="Name" :rules="[
                  { required: true, message: 'Name is required.' },
                  { maxLength: 35, minLength: 2, message: '2-35 characters.' }
                ]">
                  <a-input v-model="form.from_name" placeholder="Required 2-35 characters" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="from_companyanme" label="Company Name" :rules="[
                  { maxLength: 35, minLength: 2, message: '2-35 characters.' }
                ]">
                  <a-input v-model="form.from_companyanme" placeholder="Optional 2-35 characters" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="from_street1" label="Address Line 1" :rules="[
                  { required: true, message: 'Address line1 is required.' },
                  { maxLength: 35, minLength: 4, message: '4-35 characters.' }
                ]">
                  <a-input v-model="form.from_street1" placeholder="Required 4-35 characters" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="from_street2" label="Address Line 2"
                  :rules="[{ maxLength: 35, minLength: 1, message: '1-35 characters.' }]">
                  <a-input v-model="form.from_street2" placeholder="Optional 1-35 characters" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="from_zip" label="Zip Code" :rules="[
                  {
                    required: true,
                    type: 'number',
                    message: 'Zip code is required.'
                  },
                  { minLength: 5, maxLength: 10, message: '5-10 characters' }
                ]">
                  <a-config-provider :locale="enUS">
                    <a-select allow-search placeholder="Required 5-10 characters" v-model="form.from_zip"
                      @change="fromChange" @search="fromZipSearch" allow-clear :loading="fromLoading"
                      :options="fromOptions" autocomplete="off" @popup-visible-change="selectFirst">
                      <template #empty>
                        <a-empty v-if="(fromIsempty === true)" />
                        <div class="empty" v-if="(fromZiperror === true)">
                          <a-space>
                            <icon-info-circle />
                            Zip error
                          </a-space>
                        </div>
                        <div class="noarea" v-if="(fromNoarea === true)">
                          <icon-empty />
                          Not in the delivery service area
                        </div>
                      </template>
                    </a-select>
                  </a-config-provider>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="from_city" label="City" :rules="[
                  { required: true, message: 'City is required' },
                  { maxLength: 35, minLength: 2, message: '2-35 characters.' }
                ]">
                  <a-input v-model="form.from_city" placeholder="Required 2-35 characters" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="from_state" label="State" :rules="[
                  { required: true, message: 'State is required' }
                ]">
                  <a-config-provider :locale="enUS">
                    <a-select v-model="form.from_state" placeholder="Please select" @popup-visible-change="selectFirst"
                      allow-search>
                      <a-option v-for="item in state" :key="item" :value="item.code">{{ item.code }} - {{ item.name }}
                      </a-option>
                    </a-select>
                  </a-config-provider>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="from_country" label="Country" :rules="[
                  { required: true, message: 'Country is required' }
                ]">
                  <a-select v-model="form.from_country" placeholder="Please select" disabled>
                    <a-option value="US">United State</a-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item field="from_phone" label="Phone" :rules="[
                  { required: true, message: 'Phone is required' },
                  { minLength: 10, maxLength: 15, message: '10-15 characters' }
                ]">
                  <a-input v-model="form.from_phone" placeholder="Required 5-10 characters" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="2">
                <a-form-item field="from_extension" label="Extension"
                  :rules="[{ minLength: 1, maxLength: 6, message: '1-6 characters' }]">
                  <a-input v-model="form.from_extension" placeholder="Optional" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="from_email" label="Email" :rules="[
                  {
                    match: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/,
                    message: 'email validation failed'
                  }
                ]">
                  <a-input v-model="form.from_email" placeholder="Optional" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="6" class="save">
                <a-form-item field="from_saveaddress">
                  <a-checkbox v-model="form.from_saveaddress" @change="inputFirst">Save to My Address
                  </a-checkbox>
                </a-form-item>
              </a-col>
            </a-row>
          </a-collapse-item>
        </a-collapse>
        <a-collapse class="ReturnAddress" expand-icon-position="right" style="margin-top:24px;">
          <a-collapse-item key="2">
            <template #expand-icon="{ active }">
              <a-button shape="circle" v-if="active">
                <icon-double-down />
              </a-button>
              <a-button shape="circle" v-else>
                <icon-double-up />
              </a-button>
            </template>
            <template #extra>
              <div class="select-address">
                <div class="selecr-title">Address</div>
                <a-config-provider :locale="enUS">
                  <a-select @change="returnAddressVal" v-model="returnSelect" @click.stop="" style="width:360px;"
                    placeholder="Please select" @popup-visible-change="selectFirst">
                    <a-option v-for="item, i in returnAddress.option" :value="item.name" :key="i">{{
                      item.name
                    }}
                    </a-option>
                  </a-select>
                </a-config-provider>
              </div>
            </template>
            <template #header>
              <div class="collapse-header">
                <div class="title">Return</div>
                <div class="address">
                  <span v-if="form.return_name">{{ form.return_name }},</span>
                  <span v-if="form.return_companyanme">{{ form.return_companyanme }},</span>
                  <span v-if="form.return_street1">{{ form.return_street1 }},</span>
                  <span v-if="form.return_street2">{{ form.return_street2 }},</span>
                  <span v-if="form.return_city">{{ form.return_city }},</span>
                  <span v-if="form.return_state">{{ form.return_state }}</span>
                </div>
              </div>
            </template>
            <a-row :gutter="[20]">
              <a-col :span="12">
                <a-form-item field="return_name" label="Name" :rules="returnRules.username">
                  <a-input v-model="form.return_name" placeholder="Required 2-35 characters" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="return_companyanme" :rules="returnRules.companyname" label="Company Name">
                  <a-input v-model="form.return_companyanme" placeholder="Optional 2-35 characters" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="return_street1" :rules="returnRules.address1" label="Address Line 1">
                  <a-input v-model="form.return_street1" placeholder="Required 4-35 characters" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="return_street2" label="Address Line 2"
                  :rules="[{ maxLength: 35, minLength: 1, message: '1-35 characters' }]">
                  <a-input v-model="form.return_street2" placeholder="Optional 1-35 characters" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="return_zip" :rules="returnRules.zip" label="Zip Code">
                  <a-config-provider :locale="enUS">
                    <a-select allow-search placeholder="Required 5-10 characters" v-model="form.return_zip"
                      @change="returnChange" @search="ReturnZipSearch" :options="returnOptions" :loading="returnLoading"
                      allow-clear @popup-visible-change="selectFirst">
                      <template #empty>
                        <a-empty v-if="(returnIsempty === true)" />
                        <div class="empty" v-if="(returnZiperror === true)">
                          <a-space>
                            <icon-info-circle />
                            Zip error
                          </a-space>
                        </div>
                        <div class="noarea" v-if="(returnNoarea === true)">
                          <icon-empty />
                          Not in the delivery service area
                        </div>
                      </template>
                    </a-select>
                  </a-config-provider>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="return_city" :rules="returnRules.city" label="City">
                  <a-input v-model="form.return_city" placeholder="Required 2-35 characters" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="return_state" :rules="returnRules.state" label="State">
                  <a-config-provider :locale="enUS">
                    <a-select v-model="form.return_state" placeholder="Please select" @popup-visible-change="selectFirst"
                      allow-search>
                      <a-option v-for="item in state" :key="item" :value="item.code">{{ item.code }} - {{
                        item.name
                      }}
                      </a-option>
                    </a-select>
                  </a-config-provider>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="return_country" label="Country">
                  <a-select v-model="form.return_country" placeholder="Please select" disabled>
                    <a-option value="US">United State</a-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item field="return_phone" :rules="returnRules.phone" label="Phone">
                  <a-input v-model="form.return_phone" placeholder="Required 5-10 characters" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="2">
                <a-form-item field="return_extension" label="Extension"
                  :rules="[{ minLength: 1, maxLength: 6, message: '1-6 characters' }]">
                  <a-input v-model="form.return_extension" placeholder="Optional" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="return_email" label="Email" :rules="[
                  {
                    match: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/,
                    message: 'email validation failed'
                  }
                ]">
                  <a-input v-model="form.return_email" placeholder="Optional" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="6" class="save">
                <a-form-item field="return_saveaddress">
                  <a-checkbox v-model="form.return_saveaddress" @change="inputFirst">Save to My Address
                  </a-checkbox>
                </a-form-item>
              </a-col>
            </a-row>
          </a-collapse-item>
        </a-collapse>
        <a-collapse class="RecipientAddress" expand-icon-position="right" style="margin-top:24px;"
          :default-active-key="['3']">
          <a-collapse-item key="3">
            <template #expand-icon="{ active }">
              <a-button shape="circle" v-if="active">
                <icon-double-down />
              </a-button>
              <a-button shape="circle" v-else>
                <icon-double-up />
              </a-button>
            </template>
            <template #extra>
              <div class="select-address">
                <div class="selecr-title">Address</div>
                <a-config-provider :locale="enUS">
                  <a-select @change="toAddressVal" v-model="recipientSelect" @click.stop="" style="width:360px;"
                    placeholder="Please select" @popup-visible-change="selectFirst">
                    <a-option v-for="item, i in toAddress.option" :value="item.name" :key="i">{{ item.name }}
                    </a-option>
                  </a-select>
                </a-config-provider>
              </div>
            </template>
            <template #header>
              <div class="collapse-header">
                <div class="title">Recipient</div>
                <div class="address">
                  <span v-if="form.to_name">{{ form.to_name }},</span>
                  <span v-if="form.to_company">{{ form.to_company }},</span>
                  <span v-if="form.to_street1">{{ form.to_street1 }},</span>
                  <span v-if="form.to_street2">{{ form.to_street2 }},</span>
                  <span v-if="form.to_city">{{ form.to_city }},</span>
                  <span v-if="form.to_state">{{ form.to_state }}</span>
                </div>
              </div>
            </template>
            <a-row :gutter="[20]">
              <a-col :span="12">
                <a-form-item field="to_name" label="Name" :rules="[
                  { required: true, message: 'Name is required' },
                  { maxLength: 35, minLength: 2, message: '2-35 characters' }
                ]">
                  <a-input v-model="form.to_name" placeholder="Required 2-35 characters" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="to_company" label="Company Name" :rules="[
                  { maxLength: 35, minLength: 2, message: '2-35 characters.' }
                ]">
                  <a-input v-model="form.to_company" placeholder="Optional 2-35 characters" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field="to_street1" label="Address Line 1" :rules="[
                  { required: true, message: 'Address line 1 is required.' },
                  { maxLength: 35, minLength: 4, message: '4-35 characters.' }
                ]">
                  <a-input v-model="form.to_street1" placeholder="Required 4-35 characters" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item field=" to_street2" label="Address Line 2"
                  :rules="[{ maxLength: 35, minLength: 1, message: '1-35 characters.' }]">
                  <a-input v-model="form.to_street2" placeholder="Optional 1-35 characters" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="to_zip" label="Zip Code" :rules="[
                  {
                    required: true,
                    type: 'number',
                    message: 'Zip code is required.'
                  },
                  { minLength: 5, maxLength: 10, message: '5-10 characters' }
                ]">
                  <a-config-provider :locale="enUS">
                    <a-select allow-search placeholder="Required 5-10 characters" v-model="form.to_zip" @change="toChange"
                      @search="toZipSearch" :options="toOptions" :loading="toLoading" allow-clear
                      @popup-visible-change="selectFirst">
                      <template #empty>
                        <a-empty v-if="(toIsempty === true)" />
                        <div class="empty" v-if="(toZiperror === true)">
                          <a-space>
                            <icon-info-circle />
                            Zip error
                          </a-space>
                        </div>
                        <div class="noarea" v-if="(toNoarea === true)">
                          <icon-empty />
                          Not in the delivery service area
                        </div>
                      </template>
                    </a-select>
                  </a-config-provider>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="to_city" label="City" :rules="[
                  { required: true, message: 'City is required' },
                  { maxLength: 35, minLength: 2, message: '2-35 characters.' }
                ]">
                  <a-input v-model="form.to_city" placeholder="Required 2-35 characters" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="to_state" label="State" :rules="[
                  { required: true, message: 'State is required' }
                ]">
                  <a-config-provider :locale="enUS">
                    <a-select v-model="form.to_state" placeholder="Please select" @popup-visible-change="selectFirst"
                      allow-search>
                      <a-option v-for="item in state" :key="item" :value="item.code">{{ item.code }} - {{ item.name }}
                      </a-option>
                    </a-select>
                  </a-config-provider>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="to_country" label="Country" :rules="[
                  { required: true, message: 'Country is required' }
                ]">
                  <a-select v-model="form.to_country" placeholder="Please select" disabled>
                    <a-option value="US">United State</a-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="4">
                <a-form-item field="to_phone" label="Phone" :rules="[
                  { required: true, message: 'Phone is required' },
                  { minLength: 10, maxLength: 15, message: 'Required 10-15 characters' }
                ]">
                  <a-input v-model="form.to_phone" placeholder="Required 5-10 characters" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="2">
                <a-form-item field=" to_extension" label="Extension"
                  :rules="[{ minLength: 1, maxLength: 6, message: '1-6 characters' }]">
                  <a-input v-model="form.to_extension" placeholder="Optional" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item field="to_email" label="Email" :rules="[
                  {
                    match: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/,
                    message: 'email validation failed'
                  }
                ]">
                  <a-input v-model="form.to_email" placeholder="Optional" @focus="inputFirst" />
                </a-form-item>
              </a-col>
              <a-col :span="6" class="save">
                <a-form-item field="to_addresstype">
                  <a-checkbox v-model="form.to_addresstype" @change="inputFirst">
                    Residential
                    Address
                  </a-checkbox>
                </a-form-item>
              </a-col>
              <a-col :span="6" class="save">
                <a-form-item field="to_savecustoms" @change="inputFirst">
                  <a-checkbox v-model="form.to_savecustoms">Save to Customers
                  </a-checkbox>
                </a-form-item>
              </a-col>
            </a-row>
          </a-collapse-item>
        </a-collapse>
        <a-card class="OtherInformation" title="Other Information" style="margin-top:24px;">
          <a-row :gutter="[20, 20]">
            <a-col :span="6">
              <a-form-item field="ponum" label="PO Number"
                :rules="[{ minLength: 3, maxLength: 40, message: '3-40 characters' }]">
                <a-input v-model="form.ponum" placeholder="Optional 3-40 characters" @focus="inputSecond" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item field="invoicenum" label="Invoice Number"
                :rules="[{ minLength: 3, maxLength: 40, message: '3-40 characters' }]">
                <a-input v-model="form.invoicenum" placeholder="Optional 3-40 characters" @focus="inputSecond" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item field="signature" label="Signature Option">
                <a-select v-model="form.signature" @popup-visible-change="selectSecond">
                  <a-option v-for="item, key, i in signOptions" :key="key" :value="i + 1">{{ item }}</a-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item field="dropofftype" label="Drop Off Type">
                <a-select v-model="form.dropofftype" placeholder="Optional" @popup-visible-change="selectSecond">
                  <a-option v-for="item, k, i in dropTypes" :value="i + 1" :key="k">{{ item }}</a-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item field="reference" label="Reference"
                :rules="[{ minLength: 3, maxLength: 35, message: '3-40 characters' }]">
                <a-input v-model="form.reference" placeholder="Optional 3-40 characters" @focus="inputSecond" />
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item field="shipdate" label="Ship Date">
                <a-config-provider :locale="enUS">
                  <a-date-picker v-model="form.shipdate" placeholder="Optional" style="width:100%;" :format="dateFormate"
                    @popup-visible-change="selectSecond" />
                </a-config-provider>
              </a-form-item>
            </a-col>
          </a-row>
        </a-card>
        <div class="ShipmentInformation shipments-title">Shipment(s) Information</div>
        <div class="shipments-info">
          <div v-for="(item, index) in form.packageInfo" :key="index" id="package">
            <a-row :gutter="[20, 0]">
              <a-col :span="1" class="label-border">
                <a-form-item label="NO." :hide-label="index > 0">
                  <span class="ship-num">{{ index + 1 }}</span>
                </a-form-item>
              </a-col>
              <a-col :span="3">
                <a-form-item label="Quantity" :hide-label="index > 0" :field="'packageInfo.' + index + '.quantity'"
                  :rules="[
                    {
                      required: true,
                      message: 'Required'
                    }
                  ]">
                  <a-input-number v-model="item.quantity" :min="1" :max="200" placeholder="Required"
                    @focus="inputSecond" />
                </a-form-item>
              </a-col>
              <a-col :span="3">
                <a-form-item label="SKU" :field="'packageInfo.' + index + '.sku'" :hide-label="index > 0">
                  <a-config-provider :locale="enUS">
                    <a-select :allow-search="{ retainInputValue: true }" :filter-option="false" v-model="item.sku"
                      @change="skuChange(index)" @search="skuSearch($event, index)" allow-clear placeholder="Optional"
                      @popup-visible-change="selectSecond">
                      <a-option v-for="item, i of skuOption" :value="item.value" :key="i">{{ item.sku }}</a-option>
                    </a-select>
                  </a-config-provider>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="Dimension(in)" :hide-label="index > 0" :content-flex="true">
                  <a-row :gutter="[20, 0]">
                    <a-col :span="8">
                      <a-form-item :field="'packageInfo.' + index + '.length'" hide-label :rules="[
                        {
                          required: true,
                          message: 'required'
                        },
                        { type: 'number', max: 108, min: 1, message: '1-108' }
                      ]">
                        <a-input v-model="item.length" placeholder="L" @focus="inputSecond">
                          <template #suffix>
                            In
                          </template>
                        </a-input>
                      </a-form-item>
                    </a-col>
                    <a-col :span="8">
                      <a-form-item :field="'packageInfo.' + index + '.width'" hide-label :rules="[
                        {
                          required: true,
                          message: 'required'
                        },
                        { type: 'number', max: 108, min: 1, message: 'be 1-108' }
                      ]">
                        <a-input v-model="item.width" placeholder="W" @focus="inputSecond">
                          <template #suffix>
                            In
                          </template>
                        </a-input>
                      </a-form-item>
                    </a-col>
                    <a-col :span="8">
                      <a-form-item :field="'packageInfo.' + index + '.height'" hide-label :rules="[
                        {
                          required: true,
                          message: 'required'
                        },
                        { type: 'number', max: 108, min: 1, message: '1-108' }
                      ]">
                        <a-input v-model="item.height" placeholder="H" @focus="inputSecond">
                          <template #suffix>
                            In
                          </template>
                        </a-input>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-form-item>
              </a-col>
              <a-col :span="5">
                <a-form-item label="Weight" :content-flex="true" :hide-label="index > 0">
                  <a-row :gutter="[20, 0]">
                    <a-col :span="12">
                      <a-form-item hide-label :field="'packageInfo.' + index + '.weight'" :rules="[{
                        validator: weight1Validate(item)
                      }]" :validate-trigger="['change', 'input', 'blur']">
                        <a-input v-model="item.weight" @focus="inputSecond">
                          <template #suffix>
                            Lb
                          </template>
                        </a-input>
                      </a-form-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-item :field="'packageInfo.' + index + '.weight2'" :rules="[{
                        validator: weight2Validate(item)
                      }]" hide-label :validate-trigger="['change', 'input', 'blur']">
                        <a-input v-model="item.weight2" @focus="inputSecond">
                          <template #suffix>
                            Oz
                          </template>
                        </a-input>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-form-item>
              </a-col>
              <a-col :span="5">
                <a-form-item label="Declared Value" :hide-label="index > 0"
                  :field="'packageInfo.' + index + '.insurance'">
                  <a-input v-model="item.insurance" placeholder="Optional" @focus="inputSecond">
                    <template #prefix>
                      $
                    </template>
                  </a-input>
                </a-form-item>
              </a-col>
              <a-col :span="1" :style="index > 0 ? '' : 'line-height:112px'">
                <a-button shape="circle" @click="deleteItem(item, index)" :disabled="form.packageInfo.length === 1">
                  <icon-delete />
                </a-button>
              </a-col>
            </a-row>
          </div>
          <a-popover position="left">
            <a-button shape="circle" @click="addPackage" :disabled="totalQuantity >= 200 || totalWeight >= 30000">
              <icon-plus />
            </a-button>
            <template #content>
              <div class="add-cont">Add Shipment</div>
              <a-input-number :style="{ width: '125px' }" placeholder="1~10" mode="button" :max="10" :min="1"
                v-model="addNums" />
            </template>
          </a-popover>
        </div>
      </a-form>
    </div>

    <div class="addnew-footer" id="operateFooter">
      <div class="warn-tips">
        <div class="items">{{ totalQuantity }} items</div>
      </div>
      <div class="tips">
        <div class="tips-center">
          <div style="margin-right:24px;">
            <div class="quanty">
              <span class="total-q-til">Total Quantity: </span>
              <span class="total-q-num">{{ totalQuantity }}</span>
              <a-progress v-if="totalQuantity <= 120" type="circle" size="mini" :percent="totalQuantity / 200"
                status='' />
              <a-progress v-if="totalQuantity > 120 && totalQuantity <= 180" type="circle" size="mini" status='warning'
                :percent="totalQuantity / 200" />
              <a-progress v-if="totalQuantity && totalQuantity > 180" type="circle" size="mini" status='danger'
                :percent="totalQuantity / 200" />
            </div>
            <div>
              <a-divider direction="vertical" class="line" />
              <span class="tip-cont">The maximum quantity of all shipments is <span class="tip-num">200</span></span>
            </div>
          </div>
          <div>
            <div class="quanty">
              <span class="total-q-til">Total Weight(lb): </span>
              <span class="total-q-num">{{ totalWeight }}</span>
              <a-progress v-if="totalWeight <= 18000" type="circle" size="mini" :percent="totalWeight / 30000" />
              <a-progress v-if="totalWeight > 18000 && totalWeight <= 27000" type="circle" size="mini"
                :percent="totalWeight / 30000" status='warning' />
              <a-progress v-if="totalWeight > 27000" type="circle" size="mini" :percent="totalWeight / 30000"
                status='danger' />
            </div>
            <div>
              <a-divider direction="vertical" class="line" />
              <span class="tip-cont">The maximum quantity of all shipments is <span
                  class="tip-num">150x200=30000</span></span>
            </div>
          </div>
        </div>
        <a-space>
          <a-popconfirm content="Are you sure you want to reset?" type="warning" ok-text="Reset" cancel-text="Cancel"
            @ok="confirmReset" class="warnPop">
            <a-button shape="round">Reset</a-button>
          </a-popconfirm>
          <a-button type="primary" shape="round" @click="secondQuote">Quote</a-button>
        </a-space>
      </div>
    </div>
    <!-- 报价的弹框 -->
    <a-modal v-model:visible="quoteVisible" width="546" :hideCancel="true" :render-to-body="false" :mask-closable="false"
      :closable="false">
      <template #title>
        <div class="quote-top">
          Quotation
          <span class="close">
            <a-popconfirm content="Are you sure to give up paying the order?" position="right" ok-text="Give Up"
              cancel-text="Cancel" class="warnPop" style="width:273px;" @ok="quoteCancel" type="warning">
              <a-tooltip content="Pay later" position="right">
                <icon-close />
              </a-tooltip>
            </a-popconfirm>
          </span>
        </div>
      </template>
      <div class="quoteTitle quote-flex">
        <div>
          Order Summary
          <a-popconfirm position="left" type="warning"
            content="Re-editing the order may result in a different quoted amount than the current one."
            :content-style="{ width: 275 + 'px' }" ok-text="I Know" cancel-text="Cancel" @ok="quoteVisible = false">
            <icon-edit class="icon-edit" />
          </a-popconfirm>
        </div>
        <a-popconfirm content="Are you sure you want to delete
                      this order?" position="bottom" ok-text="Comfirm Delete" cancel-text="Cancel" class="deletePop"
          style="width:273px;" @ok="deleteOrder" type="warning">
          <icon-delete />
        </a-popconfirm>
      </div>
      <div class="quote-flex quote-label-1">
        <div>Shipment Quantity</div>
        <div>{{ shipmentNum }}</div>
      </div>
      <div class="quote-flex quote-label-1">
        <div>Delivery Date</div>
        <div>{{ createPrice.cont.deliveryDate }}</div>
      </div>
      <div class="quote-flex quote-label-1">
        <div>Service Type</div>
        <div>{{ createPrice.cont.carrier }}&nbsp;{{ createPrice.cont.serviceType }}</div>
      </div>
      <div class=" quote-flex quote-label-2">
        <div>List Price</div>
        <div style="text-decoration:line-through;">${{ createPrice.cont.listPrice }}</div>
      </div>
      <div class="quote-flex quote-label-1">
        <div>Base charge</div>
        <div style="text-decoration:line-through;">${{ createPrice.cont.baseCharge }}</div>
      </div>
      <div class="quote-flex quote-label-1">
        <div>Surcharge</div>
        <div style="text-decoration:line-through;">${{ createPrice.cont.surcharge }}</div>
      </div>
      <div class="quote-flex quote-label-2">
        <div>Your Price</div>
        <div class="yourPrice">${{ createPrice.cont.userPrice }}</div>
      </div>
      <div class="quote-flex quote-label-1">
        <div>Base charge</div>
        <div>${{ uesrBasecharge }}</div>
      </div>
      <div class="quote-flex quote-label-1">
        <div>Surcharge</div>
        <div>${{ ueserSurcharge }}</div>
      </div>
      <div class="quote-flex quote-label-3" v-for="val, k in createPrice.cont.additional" :key="k">
        <div>-{{ k }}</div>
        <div>${{ val }}</div>
      </div>
      <template #footer>
        <a-space>
          <a-tooltip position="right">
            <a-button>Est. Cost:</a-button>
            <template #content>
              <div>Base charge:${{ uesrBasecharge }}</div>
              <div>Surcharge:${{ ueserSurcharge }}</div>
              <div v-for="val, k in createPrice.cont.additional" :key="k">
                <div>{{ k }}:${{ val }}</div>
              </div>
            </template>
          </a-tooltip>
          <span class="payPrice">${{ createPrice.cont.userPrice }}</span>
          <a-button type="primary" shape="round" @click="payOrder">Pay & Get labels</a-button>
        </a-space>
        <div class="quote-tips">
          By paying your order,you agree to our <span>company privacy</span> and
          <span>conditions of use.</span>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { ref, reactive, watch } from 'vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { Notification, Button } from '@arco-design/web-vue'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import { IconPlus, IconDelete, IconEdit, IconDoubleDown, IconDoubleUp, IconClose, IconInfoCircle, IconEmpty } from '@arco-design/web-vue/es/icon'
import local from '@/utils/local'
// 使用pinia
import { mainStore } from '@/store/index.js'
// 引入api中的接口
import { addOrder, getLabel, getZipCode, addressList, getAddressType, orderCancel, getSkuCode } from '@/api/order.js'
// 使用loading
import { load } from '@/utils/loading.js'
export default {
  name: 'addneworder'
}
</script>

<script setup>

const store = mainStore()
const router = useRouter()
const state = JSON.parse(local.get('configInfo')).state
const signOptions = JSON.parse(local.get('configInfo')).order_sign_options
const dropTypes = JSON.parse(local.get('configInfo')).order_drop_types
// 表单提交
const form = reactive({
  from_name: '',
  from_companyanme: '',
  from_street1: '',
  from_street2: '',
  from_city: '',
  from_state: '',
  from_country: 'US',
  from_zip: '',
  from_email: '',
  from_phone: '',
  from_extension: '',
  from_saveaddress: false,
  return_name: '',
  return_companyanme: '',
  return_street1: '',
  return_street2: '',
  return_city: '',
  return_state: '',
  return_country: 'US',
  return_zip: '',
  return_email: '',
  return_phone: '',
  return_extension: '',
  return_saveaddress: false,
  to_name: '',
  to_phone: '',
  to_company: '',
  to_street1: '',
  to_street2: '',
  to_city: '',
  to_state: '',
  to_country: 'US',
  to_zip: '',
  to_email: '',
  to_extension: '',
  to_addresstype: false,
  to_savecustoms: false,
  ponum: '',
  invoicenum: '',
  dropofftype: '',
  signature: 1,
  shipdate: '',
  reference: '',
  packageInfo: [
    {
      quantity: 1,
      sku: '',
      length: '',
      width: '',
      height: '',
      weight: '',
      weight2: '',
      insurance: ''
    }
  ]
})
// sender-------------------------------------------
const senderSelect = ref('')
const senderAddress = reactive({
  option: []
})
const getSenderAddress = async () => {
  const msg = await addressList({
    page: 1,
    type: 1,
    searchItem: ''
  })
  if (msg.code === 0 && msg.data) {
    senderAddress.option = msg.data.data
    // 设置默认值
    let defaultAddress = msg.data.data[0]
    form.from_name = defaultAddress.name
    form.from_companyanme = defaultAddress.company
    form.from_street1 = defaultAddress.street1
    form.from_street2 = defaultAddress.street2
    form.from_city = defaultAddress.city
    form.from_state = defaultAddress.state
    form.from_zip = defaultAddress.zip
    form.from_phone = defaultAddress.phone
    form.from_extension = defaultAddress.extension
    form.from_email = defaultAddress.email
  }
}
getSenderAddress()
const senderAddressVal = (val) => {
  senderAddress.option.forEach((item) => {
    if (item.name === val) {
      form.from_name = item.name
      form.from_companyanme = item.company
      form.from_street1 = item.street1
      form.from_street2 = item.street2
      form.from_city = item.city
      form.from_state = item.state
      form.from_zip = item.zip
      form.from_phone = item.phone
      form.from_extension = item.extension
      form.from_email = item.email
    }
  })
}
const numReg = /^[0-9]*$/
const fromOptions = ref([])
const fromData = ref([])
const fromNoarea = ref(false)
const fromIsempty = ref(true)
const fromZiperror = ref(false)
const fromLoading = ref(false)
const fromZipSearch = async (value) => {
  if (!value) {
    fromIsempty.value = true
    fromNoarea.value = false
    fromZiperror.value = false
  } else {
    fromIsempty.value = false
  }
  if (!numReg.test(value)) {
    fromZiperror.value = true
    fromNoarea.value = false
  } else {
    fromZiperror.value = false
    if (value.length > 2) {
      fromLoading.value = true
      const msg = await getZipCode({
        term: value
      })
      if (msg.data.length > 0) {
        fromData.value = msg.data
        fromLoading.value = false
        fromNoarea.value = false
        fromOptions.value = []
        for (let i = 0; i < msg.data.length; i++) {
          fromOptions.value.push(msg.data[i].zip)
        }
      } else {
        fromLoading.value = false
        fromData.value = []
        fromOptions.value = []
        fromNoarea.value = true
      }
    }
  }
}
const fromChange = (value) => {
  if (fromData.value !== []) {
    fromData.value.forEach((item) => {
      if (item.value === value) {
        form.from_city = item.city
        form.from_state = item.state
        form.from_zip = item.zip
      }
    })
  }
}
// recipient--------------------------------------------
const toAddress = reactive({
  option: []
})
const recipientSelect = ref('')
const getToAddress = async () => {
  const msg = await addressList({
    page: 1,
    type: 2,
    searchItem: ''
  })
  if (msg.code === 0 && msg.data) {
    toAddress.option = msg.data.data
  }
}
getToAddress()
const toAddressVal = (val) => {
  toAddress.option.forEach((item) => {
    if (item.name === val) {
      form.to_name = item.name
      form.to_company = item.company
      form.to_street1 = item.street1
      form.to_street2 = item.street2
      form.to_city = item.city
      form.to_state = item.state
      form.to_zip = item.zip
      form.to_phone = item.phone
      form.to_extension = item.extension
      form.to_email = item.email
    }
  })
}
const toChange = (value) => {
  if (toData.value !== []) {
    toData.value.forEach((item) => {
      if (item.value === value) {
        form.to_city = item.city
        form.to_state = item.state
        form.to_zip = item.zip
      }
    })
  }
}
const toNoarea = ref(false)
const toIsempty = ref(true)
const toZiperror = ref(false)
const toLoading = ref(false)
const toOptions = ref([])
const toData = ref([])
const toZipSearch = async (value) => {
  if (!value) {
    toIsempty.value = true
    toNoarea.value = false
    toZiperror.value = false
  } else {
    toIsempty.value = false
  }
  if (!numReg.test(value)) {
    toZiperror.value = true
    toNoarea.value = false
  } else {
    toZiperror.value = false
    if (value.length > 2) {
      toLoading.value = true
      const msg = await getZipCode({
        term: value
      })
      if (msg.data.length > 0) {
        toData.value = msg.data
        toNoarea.value = false
        toOptions.value = []
        toLoading.value = false
        for (let i = 0; i < msg.data.length; i++) {
          toOptions.value.push(msg.data[i].zip)
        }
      } else {
        toNoarea.value = true
        toLoading.value = false
        toOptions.value = []
        toData.value = []
      }
    }
  }
}
// return-------------------------------------------------------
const returnRequire = reactive({
  name: false,
  company: false,
  address1: false,
  city: false,
  state: false,
  zip: false,
  phone: false
})
const returnRules = {
  username: [
    {
      validator: (value, cd) => {
        if (value) {
          returnRequire.name = true
        } else {
          returnRequire.name = false
        }
        if (returnRequire.company || returnRequire.address1 || returnRequire.city || returnRequire.state || returnRequire.zip || returnRequire.phone) {
          if (!value) {
            cd(new Error('Name is required').message)
          } else if (value.length > 35) {
            cd(new Error('1-35 characters').message)
          }
        }
      }
    }
  ],
  address1: [
    {
      validator: (value, cd) => {
        if (value) {
          returnRequire.address1 = true
        } else {
          returnRequire.address1 = false
        }
        if (returnRequire.name || returnRequire.company || returnRequire.city || returnRequire.state || returnRequire.zip || returnRequire.phone) {
          if (!value) {
            cd(new Error('Address1 is required').message)
          } else if (value.length > 35) {
            cd(new Error('1-35 characters').message)
          }
        }
      }
    }
  ],
  city: [
    {
      validator: (value, cd) => {
        if (value) {
          returnRequire.city = true
        } else {
          returnRequire.city = false
        }
        if (returnRequire.name || returnRequire.company || returnRequire.address1 || returnRequire.state || returnRequire.zip || returnRequire.phone) {
          if (!value) {
            cd(new Error('City is required').message)
          } else if (value.length > 20) {
            cd(new Error('1-20 characters').message)
          }
        }
      }
    }
  ],
  state: [
    {
      validator: (value, cd) => {
        if (value) {
          returnRequire.state = true
        } else {
          returnRequire.state = false
        }
        if (returnRequire.name || returnRequire.company || returnRequire.address1 || returnRequire.city || returnRequire.zip || returnRequire.phone) {
          if (!value) {
            cd(new Error('State is required').message)
          }
        }
      }
    }
  ],
  zip: [
    {
      validator: (value, cd) => {
        if (value) {
          returnRequire.zip = true
        } else {
          returnRequire.zip = false
        }
        if (returnRequire.name || returnRequire.company || returnRequire.address1 || returnRequire.state || returnRequire.city || returnRequire.phone) {
          if (!value) {
            cd(new Error('Zip code is required').message)
          } else if (value.length > 10 && value.length < 5) {
            cd(new Error('5-10 characters').message)
          }
        }
      }
    }
  ],
  phone: [
    {
      validator: (value, cd) => {
        if (value) {
          returnRequire.phone = true
        } else {
          returnRequire.phone = false
        }
        if (returnRequire.name || returnRequire.company || returnRequire.address1 || returnRequire.state || returnRequire.city || returnRequire.zip) {
          if (!value) {
            cd(new Error('Phone is required').message)
          } else if (value.length < 10 && value.length > 5) {
            cd(new Error('5-10 characters').message)
          }
        }
      }
    }
  ]
}
const returnAddress = reactive({
  option: []
})
const returnOptions = ref([])
const returnData = ref([])
const returnSelect = ref('')
const getReturnAddress = async () => {
  const msg = await addressList({
    page: 1,
    type: 3
  })
  if (msg.code === 0 && msg.data) {
    returnAddress.option = msg.data.data
    let returnDefault = msg.data.data[0]
    form.return_name = returnDefault.name
    form.return_companyanme = returnDefault.company
    form.return_street1 = returnDefault.street1
    form.return_street2 = returnDefault.street2
    form.return_city = returnDefault.city
    form.return_state = returnDefault.state
    form.return_zip = returnDefault.zip
    form.return_phone = returnDefault.phone
    form.return_extension = returnDefault.extension
    form.return_email = returnDefault.email
  }
}
getReturnAddress()
// return address 选择回填
const returnAddressVal = (val) => {
  returnAddress.option.forEach((item) => {
    if (item.name === val) {
      form.return_name = item.name
      form.return_companyanme = item.company
      form.return_street1 = item.street1
      form.return_street2 = item.street2
      form.return_city = item.city
      form.return_state = item.state
      form.return_zip = item.zip
      form.return_phone = item.phone
      form.return_extension = item.extension
      form.return_email = item.email
    }
  })
}
const returnChange = (value) => {
  if (returnData.value !== []) {
    returnData.value.forEach((item) => {
      if (item.value === value) {
        form.return_city = item.city
        form.return_state = item.state
        form.return_zip = item.zip
      }
    })
  }
}
const returnNoarea = ref(false)
const returnIsempty = ref(true)
const returnZiperror = ref(false)
const returnLoading = ref(false)
const ReturnZipSearch = async (value) => {
  if (!value) {
    returnIsempty.value = true
    returnNoarea.value = false
    returnZiperror.value = false
  } else {
    returnIsempty.value = false
  }
  if (!numReg.test(value)) {
    returnZiperror.value = true
    returnNoarea.value = false
  } else {
    returnZiperror.value = false
    if (value.length > 2) {
      returnLoading.value = true
      const msg = await getZipCode({
        term: value
      })
      if (msg.data.length > 0) {
        returnData.value = msg.data
        returnNoarea.value = false
        returnOptions.value = []
        returnLoading.value = false
        for (let i = 0; i < msg.data.length; i++) {
          returnOptions.value.push(msg.data[i].zip)
        }
      } else {
        returnNoarea.value = true
        returnLoading.value = false
        returnOptions.value = []
        returnData.value = []
      }
    }
  }
}
const formRef = ref(null)
const isEdit = ref(false)
const addNums = ref(1)
const addPackage = () => {
  status1.value = 'wait'
  status2.value = 'process'
  if (addNums.value === '' || addNums.value === 1) {
    form.packageInfo.push({
      quantity: 1,
      sku: '',
      length: '',
      width: '',
      height: '',
      weight: '',
      weight2: '',
      insurance: ''
    })
  } else if (addNums.value > 1) {
    for (let i = 0; i < addNums.value; i++) {
      form.packageInfo.push({
        quantity: 1,
        sku: '',
        length: '',
        width: '',
        height: '',
        weight: '',
        weight2: '',
        insurance: ''
      })
    }
  }
}
function deleteItem (item, index) {
  status1.value = 'wait'
  status2.value = 'process'
  form.packageInfo.splice(index, 1)
}
// 计算total quantity
let moreQuantity = false
const totalQuantity = ref(0)
watch(() => form.packageInfo, (newVal) => {
  let totalCount = 0
  newVal.forEach((item) => {
    totalCount += Number(item.quantity) || 0
  })
  totalQuantity.value = totalCount
  if (moreQuantity !== true) {
    if (totalCount > 200) {
      Notification.error({
        id: 'quantity',
        title: 'Too many shipments!',
        duration: 0,
        content: 'Please reduce the number of shipments to within 200.',
        closable: true
      })
    }
    moreQuantity = true
  }
  if (totalCount <= 200) {
    Notification.remove('quantity')
    moreQuantity = false
  }
}, { immediate: true, deep: true })
// 计算重量
let moreWeight = false
const totalWeight = ref(0)
watch(() => form.packageInfo, (newVal) => {
  let totalweight = 0
  newVal.forEach((item) => {
    let weight = Number(item.weight) + Number(item.weight2) / 16 || 0
    let quantity = Number(item.quantity) || 0
    totalweight += weight * quantity
  })
  totalWeight.value = totalweight.toFixed(2)
  if (moreWeight !== true) {
    if (totalweight > 30000) {
      Notification.error({
        id: 'weight',
        title: 'Out of weight!',
        duration: 0,
        content: 'Please reduce the weight of packages to within 30000 Lb.',
        closable: true
      })
    }
    moreWeight = true
  }
  if (totalweight <= 30000) {
    Notification.remove('weight')
    moreWeight = false
  }
}, { immediate: true, deep: true })
// 计算 长 宽 高
let moreDimension = false
watch(() => form.packageInfo, (newVal) => {
  for (let i = 0; i < newVal.length; i++) {
    let l = Number(newVal[i].length)
    let w = Number(newVal[i].width)
    let h = Number(newVal[i].height)
    let measure = 0
    let max = Math.max(l, w, h)
    if (max === l) {
      measure = l + w * 2 + h * 2
    } else if (max === w) {
      measure = w + l * 2 + h * 2
    } else {
      measure = h + l * 2 + w * 2
    }
    if (moreDimension !== true) {
      if (measure > 165) {
        Notification.error({
          id: 'dimension',
          title: 'Dimension limit!',
          duration: 0,
          content: 'The amount of length + width*2 + height*2 is no more than 165.',
          closable: true
        })
        moreDimension = true
      }
    }
    if (measure <= 165) {
      Notification.remove('dimension')
      moreDimension = false
    }
  }
}, { immediate: true, deep: true })
const secondSuccess = ref({})
const orderId = ref(0)
let createPrice = reactive({ cont: {} })
let uesrBasecharge = ref(0)
let ueserSurcharge = ref(0)
let shipmentNum = ref(0)

const secondQuote = async () => {
  formRef.value.validate(async (value) => {
    if (!value) {
      load.show('Quoting...')
      const msg = await addOrder({
        order_id: null,
        original_order_id: null,
        sender_address_id: null,
        from_name: form.from_name,
        from_company: form.from_companyanme,
        from_street1: form.from_street1,
        from_street2: form.from_street2,
        from_city: form.from_city,
        from_state: form.from_state,
        from_zip: form.from_zip,
        from_country: form.from_country,
        from_phone: form.from_phone,
        from_extension: form.from_extension,
        from_email: form.from_email,
        save_to_addresses: form.from_saveaddress,
        return_address_id: null,
        return_name: form.return_name,
        return_company: form.return_companyanme,
        return_street1: form.return_street1,
        return_street2: form.return_street2,
        return_city: form.return_city,
        return_state: form.return_state,
        return_zip: form.return_zip,
        return_country: form.return_country,
        return_phone: form.return_phone,
        return_extension: form.return_extension,
        return_email: form.return_email,
        save_to_return: form.return_saveaddress,
        recipient_address_id: null,
        to_name: form.to_name,
        to_company: form.to_company,
        to_street1: form.to_street1,
        to_street2: form.to_street2,
        to_city: form.to_city,
        to_state: form.to_state,
        to_zip: form.to_zip,
        to_country: form.to_country,
        is_residential: form.to_addresstype,
        to_phone: form.to_phone,
        to_extension: form.to_extension,
        to_email: form.to_email,
        save_to_customer: form.to_savecustoms,
        ponumber: form.ponum,
        invoice_number: form.invoicenum,
        reference: form.reference,
        signature_option: form.signature,
        ship_date: form.shipdate,
        dropoff_type: form.dropofftype,
        shipments: form.packageInfo
      })
      if (msg.code === 0) {
        load.hide()
        secondSuccess.value = msg.data
        orderId.value = msg.data.order.id
        createPrice.cont = msg.data.service
        shipmentNum.value = msg.data.shipment_count
        uesrBasecharge.value = createPrice.cont.chargeDetail['Base Charge']
        ueserSurcharge.value = createPrice.cont.chargeDetail['Surcharge']
        quoteVisible.value = true
        isEdit.value = false
      } else {
        load.hide()
        Notification.error(msg.message)
      }
    } else {
      const ShipperAddress = document.querySelector('.ShipperAddress')
      const ReturnAddresss = document.querySelector('.ReturnAddress')
      const RecipientAddress = document.querySelector('.RecipientAddress')
      const OtherInformation = document.querySelector('.OtherInformation ')
      const ShipmentInformation = document.querySelector('.ShipmentInformation')
      let actualTop = 0
      for (let key in value) {
        if (key.indexOf('from') !== -1) {
          actualTop = ShipperAddress.offsetTop
          break
        } else if (key.indexOf('return') !== -1) {
          actualTop = ReturnAddresss.offsetTop
          break
        } else if (key.indexOf('to') !== -1) {
          actualTop = RecipientAddress.offsetTop
          break
        } else if (key.indexOf('packageInfo') !== -1) {
          actualTop = ShipmentInformation.offsetTop
          break
        } else {
          actualTop = OtherInformation.offsetTop
        }
      }
      console.log(actualTop)
      if (actualTop !== 0) {
        actualTop -= 100
        let element = document.getElementsByClassName('main-cont')
        // let element = document.getElementsByClassName('mainCont')
        // let element = document.body
        // console.log(element)
        element[0].scrollTo({ top: actualTop, behavior: 'smooth' })
      }
    }
  })
}
const payOrder = async () => {
  load.show('Paying...')
  const msg = await getLabel({
    order_id: orderId.value
  })
  if (msg.code === 0) {
    load.hide()
    Notification.success({
      title: 'Success',
      content: 'Paid & Get labes.'
    })
    store.Golist = 2
    router.push('/order/list/orderlist')
    isEdit.value = false
  } else if (msg.code === 11) {
    load.hide()
    Notification.warning({
      id: 'payAddnew',
      title: 'Insufficient Balance!',
      content: 'The balance is insufficient, getting labels has been stopped, and the order will continue automatically after recharging.',
      duration: 0,
      closable: true,
      footer: <Button type="primary" size="small" shape="round" onClick={closeNotification} >
        Recharge
      </Button >
    })
  } else {
    load.hide()
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
const closeNotification = () => {
  Notification.remove('payAddnew')
  let routerUrl = router.resolve({ path: '/usercenter/recharge' })
  window.open(routerUrl.href, '_blank')
}
const deleteOrder = async () => {
  const msg = await orderCancel({
    order_id: orderId.value
  })
  if (msg.code === 0) {
    Notification.success({
      title: 'Success',
      content: 'Deleted.'
    })
    router.push('/order/list/orderlist')
  } else {
    Notification.error({
      title: 'Error',
      content: msg.message
    })
  }
}
const quoteVisible = ref(false)
const quoteCancel = () => {
  isEdit.value = false
  router.push('/order/list/orderlist')
}
// 监视sender的zip和recipient的zip
watch([() => form.to_zip, () => form.to_city, () => form.to_state, () => form.to_street1], (newValue) => {
  if (newValue[0] && newValue[1] && newValue[2] && newValue[3]) {
    validateAddressType()
  }
})
// 验证地址信息
const validateAddressType = async () => {
  const msg = await getAddressType({
    zip: form.to_zip,
    city: form.to_city,
    state: form.to_state,
    street1: form.to_street1,
    street2: form.to_street2
  })
  if (msg.code === 0 && msg.message === 'R') {
    form.to_addresstype = true
  } else {
    form.to_addresstype = false
  }
}
// 重置
const confirmReset = () => {
  formRef.value.resetFields()
  senderSelect.value = ''
  returnSelect.value = ''
  recipientSelect.value = ''
  isEdit.value = false
}

// sku---------------------------------------------------------
const skuOption = ref([])
const skuSearch = async (val, i) => {
  const msg = await getSkuCode({
    term: val
  })
  if (msg.code === 0 && msg.data.length > 0) {
    skuOption.value = msg.data
  } else {
    skuOption.value = []
    form.packageInfo[i].sku = val
  }
}
const skuChange = (i) => {
  if (skuOption.value !== []) {
    skuOption.value.forEach((item) => {
      if (item.sku === form.packageInfo[i].sku) {
        form.packageInfo[i].length = item.length
        form.packageInfo[i].sku = item.sku
        form.packageInfo[i].width = item.width
        form.packageInfo[i].height = item.height
        form.packageInfo[i].weight = item.weightLB
        form.packageInfo[i].weight2 = String(item.weightOZ)
        form.packageInfo[i].insurance = item.insurance
      }
    })
  }
}
const weight1Validate = (item) => {
  return (value, callback) => {
    if (Number(value) + Number(item.weight2) === 0) {
      callback(new Error('Greater than 0').message)
    } else if (Number(value) + (Number(item.weight2) / 16) > 150) {
      callback(new Error('Total weight can not exceed 150 lb (2400 oz)').message)
    }
  }
}
const weight2Validate = (item) => {
  return (value, callback) => {
    if (Number(value) + Number(item.weight) === 0) {
      callback(new Error('Greater than 0').message)
    } else if (Number(value) + Number(item.weight) * 16 > 2400) {
      callback(new Error('Total weight can not exceed 150 lb (2400 oz)').message)
    }
  }
}
// const formRef = ref(null)
// const checkWeight = (type) => {
//   if (type === 'weight') {
//     formRef.value.validateField('weight2')
//   } else {
//     formRef.value.validateField('weight')
//   }
// }
// 时间设置--------------------------------------------------
let dateFormate = ref('MM/DD/YYYY')
let timeFormate = JSON.parse(local.get('userInfo')).date_format
// console.log(timeFormate)
if (timeFormate === 1) {
  dateFormate.value = 'MM/DD/YYYY'
} else if (timeFormate === 2) {
  dateFormate.value = 'DD/MM/YYYY'
} else if (timeFormate === 3) {
  dateFormate.value = 'YYYY/MM/DD'
}
// 步骤---------------------------------------
const current = ref(1)
const status1 = ref('process')
const status2 = ref('wait')
const setCurrent = (val) => {
  current.value = val
  let element = document.getElementsByClassName('main-cont')
  const ShipmentInfo = document.querySelector('.OtherInformation')
  if (current.value === 1) {
    status1.value = 'process'
    status2.value = 'wait'
    element[0].scrollTo({ top: 0, behavior: 'smooth' })
  } else if (current.value === 2) {
    status1.value = 'wait'
    status2.value = 'process'
    element[0].scrollTo({ top: ShipmentInfo.offsetTop - 90, behavior: 'smooth' })
  }
}
// onMounted(() => {
//   const first = document.getElementById('firstStep')
//   const second = document.getElementById('secondStep')
//   const footer = document.getElementById('operateFooter')
//   first.addEventListener('mousemove', function (ev) {
//     var e = ev || window.event;
//     var x = e.pageX - first.offsetLeft;
//     var y = e.pageY - first.offsetTop;
//     if (x > 0 && y > 0) {
//       status1.value = 'process'
//       status2.value = 'wait'
//       status3.value = 'wait'
//     }
//   })
//   second.addEventListener('mousemove', function (ev) {
//     var e = ev || window.event;
//     var p = e.pageX - second.offsetLeft;
//     var q = e.pageY - second.offsetTop;
//     if (p > 0 && q > 0) {
//       status1.value = 'wait'
//       status2.value = 'process'
//       status3.value = 'wait'
//     }
//   })
//   footer.addEventListener('mousemove', function (ev) {
//     var e = ev || window.event;
//     var z = e.pageX - footer.offsetLeft;
//     var o = e.pageY - footer.offsetTop;
//     if (z > 0 && o > 0) {
//       status1.value = 'wait'
//       status2.value = 'wait'
//       status3.value = 'process'
//     }
//   })
// })
const selectFirst = (val) => {
  if (val) {
    status1.value = 'process'
    status2.value = 'wait'
  }
}
const inputFirst = () => {
  status1.value = 'process'
  status2.value = 'wait'
}
const selectSecond = (val) => {
  if (val) {
    status1.value = 'wait'
    status2.value = 'process'
  }
}
const inputSecond = () => {
  status1.value = 'wait'
  status2.value = 'process'
}
</script>
<style lang="less" scoped>
.empty {
  color: rgb(var(--danger-6));
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
}

.noarea {
  color: var(--color-text-4);
  font-weight: 400;
  font-size: 14px;
  padding: 8px 12px;
}

:deep(.arco-collapse-item-content) {
  background-color: #fff;
}

:deep(.arco-spin) {
  display: block;
}

.top-title {
  height: 64px;
  width: 100%;
  line-height: 64px;
  padding-left: 84px;
  padding-right: 24px;
  padding-bottom: 24px;
  font-weight: 500;
  font-size: 20px;
  color: var(--color-text-1);
  position: fixed;
  background-color: #fff;
  z-index: 2;

  // .copy-color {
  //   color: var(--color-text-2);
  //   font-size: 14px;
  // }

  // .create-time {
  //   font-weight: 400;
  //   font-size: 14px;
  //   color: var(--color-text-3);
  // }

  // .detail-status {
  //   margin-left: 16px;
  //   font-weight: 500;
  //   font-size: 14px;
  //   color: var(--color-text-1);
  //   padding: 5px 8px;
  //   background-color: var(--color-fill-2);
  //   border-radius: 8px;
  // }
}

.add-container {
  position: relative;
}

.steps {
  position: fixed;
  left: 60px;
  top: 150px;

  :deep(.arco-steps-item-title) {
    font-size: 14px;
  }
}

// 侧边步骤条
.frame-bg {
  padding: 18px 24px 40px 36px;

  .frame-aside {
    border-right: 0;
  }
}

.frame-body {
  display: flex;
  background: var(--color-bg-2);
}

.frame-aside {
  padding: 24px;
  height: 272px;
  border-right: 1px solid var(--color-border);
}

.frame-main {
  width: 100%;
}

// 表单
.select-address {
  display: flex;
  margin-right: 28px;
}

.selecr-title {
  line-height: 32px;
  margin-right: 16px;
  color: var(--color-text-2);
  font-weight: 400;
  font-size: 14px;
}

.save {
  display: flex;
  align-items: flex-end;
  height: 84px;
  justify-content: flex-end;
}

:deep(.arco-form-item-label-required-symbol) {
  display: none;
}

.return-title {
  color: var(--color-text-1);
  font-weight: 500;
  font-size: 16px;
}

.footer-add {
  height: 64px;
  line-height: 64px;
  padding-left: 20px;
  border-left: 1px solid var(--color-border-2);
  border-right: 1px solid var(--color-border-2);
  border-bottom: 1px solid var(--color-border-2);
  border-radius: 0 0 4px 4px;
}

.footer-btn {
  margin-top: 24px;
  text-align: right;
}

.shipments-title {
  margin-top: 24px;
  color: var(--color-text-1);
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 29px;
}

.ship-num {
  padding: 1px 8px;
  color: var(--color-text-1);
  font-weight: 500;
  font-size: 14px;
  background: var(--color-fill-2);
  border-radius: 8px;
  line-height: 22px;
}

#package :deep(.arco-form-item-label-col) {
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-border-2);
}

.add-cont {
  padding-bottom: 8px;
  color: var(--color-text-3);
  font-weight: 400;
  font-size: 12px;
}

.addnew-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 110px;
  padding: 20px 48px;
  border-top: 1px solid var(--color-border-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
}

.items {
  font-weight: 400;
  font-size: 14px;
  color: var(--color-text-1);
  margin-right: 48px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.warn-tips {
  display: flex;
}

.quanty {
  padding-bottom: 13px;

  .total-q-til {
    font-weight: 400;
    font-size: 14px;
    color: var(--color-text-3);
  }

  .total-q-num {
    font-weight: 600;
    font-size: 24px;
    color: var(--color-text-1);
    padding-right: 6px;
  }
}

.pay-tip {
  font-weight: 400;
  font-size: 12px;
  color: var(--color-text-3);
  line-height: 22px;

  span {
    font-weight: 700;
    font-size: 14px;
    color: rgb(var(--primary-6));
  }
}

.line {
  border-left: 2px solid var(--color-text-3);
  margin-left: 0;
  height: 18px;
}

.tip-cont {
  font-weight: 400;
  font-size: 12px;
  color: var(--color-text-1);
  line-height: 18px;
}

.tip-num {
  color: rgb(var(--warning-6));
  font-weight: 600;
}

.tips {
  display: flex;
}

.tips-center {
  display: flex;
  margin-right: 48px;
}

.from-toaddress :deep(.arco-card-body) {
  padding: 0
}

.from-toaddress-cont {
  padding: 16px;
}

.default-text {
  font-size: 12px;
  padding: 1px 8px;
  border-radius: 8px;
  color: var(--color-text-1);
  background-color: var(--color-fill-2);
}

.success-text {
  font-size: 12px;
  padding: 1px 8px;
  border-radius: 8px;
  color: rgb(var(--success-6));
  background-color: rgb(var(--success-1));
}

.collapse-header {
  display: flex;

  .title {
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-1);
  }

  .address {
    margin-left: 16px;
    font-weight: 400;
    font-size: 14px;
    color: var(--color-text-3);
  }
}

.quote-flex {
  display: flex;
  justify-content: space-between;
}

.quoteTitle {
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 12px;
  color: var(--color-text-1);
}

.important-label {
  font-size: 16px;
  line-height: 44px;

  div:first-child {
    color: #86909C;
  }

  div:nth-child(2) {
    color: #1D2129;
  }
}

.quote-label-1 {
  font-size: 14px;
  font-weight: 400;
  // line-height: 22px;
  padding: 8px 0;

  div:first-child {
    color: var(--color-text-3);
  }

  div:nth-child(2) {
    color: var(--color-text-1);
  }
}

.quote-label-2 {
  padding: 12px 0;

  div:first-child {
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-1);
  }

  div:nth-child(2) {
    color: var(--color-text-3);
    font-weight: 400;
    font-size: 14px;
  }
}

.quote-label-3 {
  font-weight: 400;
  font-size: 12px;
  padding: 4px 0;
  color: var(--color-text-3);
}

.icon-edit,
.yourPrice {
  color: rgb(var(--primary-6));
}

.quote-tips {
  font-weight: 400;
  font-size: 10px;
  color: var(--color-text-3);
  margin-top: 10px;

  span {
    color: rgb(var(--primary-6));
  }
}

.payPrice {
  font-weight: 600;
  font-size: 24px;
  color: var(--color-text-1);
}

.quote-top {
  width: 100%;
  text-align: center;
  position: relative;

  .close {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.shipments-info {
  padding-bottom: 130px;
}
</style>
<style>
.warnPop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--warning-6));
  background-color: rgb(var(--warning-1));
}

.deletePop .arco-popconfirm-popup-content .arco-popconfirm-footer .arco-btn-primary {
  color: rgb(var(--danger-6));
  background-color: rgb(var(--danger-1));
}
</style>
